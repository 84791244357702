<template>
  <section :class="[orderLoading ? 'loading' : '' ,'cart']">
    <div class="loading-icon" />

    <div class="order-items">
      <OrderItem
        v-for="item in order.items"
        :key="item.id"
        :cartversion="cart.cart_version"
        :item="item"
        :order="order"
        :showhotels="order.event_hotel_behavior"
      />
      <HotelNeeded
        v-if="order.hotel_needed"
        :eventid="order.h4h_event_id"
      />
      <PriceChangeConfirmation
        v-if="order.price_change_item"
      />
    </div>

    <CurrencySelection
      :chargecurrency="order.charge_currency"
      :chargeablecurrencies="order.chargeable_currencies"
      :disabledcart="order.disabled_cart"
    />


    <AmountDisplay
      v-if="deposit_item"
      extraclass="deposit-item"
      :header="$t(&quot;cart.deposit&quot;)"
      :amount="deposit_item.price_decorated"
    />

    <DiscountForm
      v-if="!checkoutpage && !depositOrder"
    />
    <GPTDisplay />
    <AmountDisplay
      v-if="order.discount > 0"
      extraclass="discount"
      :header="$t(&quot;formPackage.discount&quot;)"
      :amount="&quot;(&quot; + order.discount_decorated + &quot;)&quot;"
    />

    <AmountDisplay
      v-if="order.h4h_points > 0"
      extraclass="h4h-program"
      :header="cart.h4h_program"
      :amount="&quot;(&quot; + order.h4h_points_decorated + &quot;)&quot;"
    />
    <AmountDisplay
      v-if="refundableBooking"
      extraclass="protect-group-fee"
      :header="$t(&quot;checkout.protectGroup.refundableBooking&quot;)"
      :amount="order.protect_group_cost_decorated"
    />

    <AmountDisplay
      v-if="creditUsed && order.customer_total_credit > 0"
      extraclass="cart-footer credit-amount negative-amount"
      :header="$t(&quot;cart.credit&quot;)"
      :amount="`(${order.credit_amount_decorated})`"
    />

    <AmountDisplay
      v-if="order.change_order"
      extraclass="change-order-subtotal"
      :header="$t('cart.subtotal')"
      :amount="order.change_order_subtotal"
    />

    <AmountDisplay
      v-if="order.change_order"
      extraclass="change-order-already-paid"
      :header="$t('cart.previouslyPaid')"
      :amount="`(${order.change_order_already_paid})`"
    />

    <AmountDisplay
      v-if="order.tax > 0"
      extraclass="tax"
      :header="$t(&quot;cart.tax&quot;)"
      :amount="order.tax_decorated"
    />

    <AmountDisplay
      v-if="order.vat_tax > 0"
      extraclass="vat-tax"
      :header="$t(&quot;cart.vatTax&quot;)"
      :amount="order.vat_tax_decorated"
    />

    <AmountDisplay
      extraclass="fee"
      :header="$t('cart.processingFee')"
      :amount="order.processing_fee_decorated"
    />


    <AmountDisplay
      v-if="!order.change_order"
      extraclass="fee-and-tax"
      :header="$t('cart.processingFee')"
      :amount="order.fee_and_tax_decorated"
    />

    <AmountDisplay
      v-if="order.total_roomcash > 0"
      extraclass="roomcash"
      :header="$t('cart.roomcash')"
      :amount="order.total_roomcash_decorated"
    />


    <div class="total-charge">
      <AmountDisplay
        extraclass="total"
        :header="$t('cart.yourTotal')"
        :amount="displayAmount"
      />

      <CurrencyDisclaimer
        :currency="order.charge_currency"
        :package-currency="order.default_package_currency"
        :chargeablecurrencies="order.chargeable_currencies"
      />
    </div>

    <div class="total-exchange">
      <AmountDisplay
        extraclass="total"
        :header="$t(&quot;cart.yourTotal&quot;)"
        :amount="order.total_exchange_decorated"
      />

      <CurrencyDisclaimer
        :currency="order.currency"
        :package-currency="order.default_package_currency"
        :chargeablecurrencies="order.chargeable_currencies"
      />
    </div>
  </section>
</template>

<script>
import AmountDisplay from './amount_display'
import CurrencyDisclaimer from '../../default/currency_convert/currency-disclaimer'
import CurrencySelection from './currency_selection'
import DiscountForm from './discount_form'
import HotelNeeded from './hotel_needed'
import PriceChangeConfirmation from './price_change_confirmation'
import GPTDisplay from './gpt_display'
import OrderItem from './order_items/order_item'
import { mapMutations } from 'vuex'

export default {
  components: {
    AmountDisplay,
    CurrencyDisclaimer,
    CurrencySelection,
    DiscountForm,
    HotelNeeded,
    PriceChangeConfirmation,
    GPTDisplay,
    OrderItem,
  },

  props: {
    checkoutpage: {
      default: false,
      type: Boolean,
      required: false
    }
  },

  computed: {
    ...mapMutations('checkout', ['toggleCreditApplication']),
    depositOrder(){
      return this.order && this.deposit_product
    },
    deposit_product(){
      return this.order.items.find(item => item.sf_type == 'Deposit')
    },
    hasPaymentTerms() {
      return !(this.order.event_payment_term_options.slice(-1)[0] == 1)
    },
    refundableBooking(){
      return this.order.protect_group_amount_raw > 0
    },
    negativeAmount(){
      return this.order.credit_amount_decorated
    },
    customerTotalDecorated(){
      return this.order.customer_total_credit_decorated
    },
    cart() {
      return this.$store.state.order.cart
    },
    deposit_item(){
      return this.order.items.find( i=> i.deposit )
    },
    order(){
      return this.$store.state.order.order
    },
    orderLoading(){
      return this.$store.state.order.loading
    },
    creditUsed() {
      return this.order.credit_amount > 0
    },
    displayAmount(){
      return this.order.charge_currency + ' ' + this.order.amount_after_credit
    }
  }
}
</script>
