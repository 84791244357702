<template>
  <div class="purchasing">
    <div class="row">
      <div class="column">
        <!-- eslint-disable vue/no-v-html -->
        <p>
          Our site is undergoing routine maintenance. Please contact us at <span v-html="phoneLink" /> for additional information or come back later to submit your request.
        </p>
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    phoneLink() {
      return `<a href="tel:${this.phoneNumber}" class="phone-numbers">${this.phoneNumber}</a>`
    },

    phoneNumber() {
      let location = document.getElementById('country-code').dataset.code
      let phoneNumber = window.phone_numbers[location] || window.phone_numbers['default']
      return phoneNumber
    }
  }
}
</script>
